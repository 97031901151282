import { UploadFileStatus } from 'antd/es/upload/interface';
import styled from 'styled-components';

export const Container = styled.div<{ status: UploadFileStatus }>`
  display: flex;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  border-radius: 0.5rem;
  padding: 1.5rem;
  gap: 1.5rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.secondaryGrayBackground};

  img {
    width: 1rem;
  }
  cursor: ${({ status }) => status === 'done' && 'pointer'};
`;

export const ContentIcon = styled.div`
  display: flex;
  border-radius: 0.375rem;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colorsDesignSystem.neutralGray};
  padding: 1rem;

  svg {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colorsDesignSystem.primary};
  }
`;

export const FileName = styled.span<{ status: UploadFileStatus }>`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.375rem;
  color: ${({ theme, status }) =>
    status === 'uploading'
      ? theme.colorsDesignSystem.text
      : status === 'error'
      ? theme.colorsDesignSystem.red_secondary
      : theme.colorsDesignSystem.tabBarInactiveColor};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
`;

export const FileDescription = styled.span`
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colorsDesignSystem.grayText};
  margin-left: 1.5rem;

  b {
    font-weight: 600;
  }
`;
