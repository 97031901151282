import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';
import { ConfigProvider } from 'antd';
import { Routes } from './routes/routes';
import './styles/App.css';
import { GlobalStyle } from './styles/global';
import theme from './styles/theme';
import { antTheme } from './styles/antTheme';
import { CmsProvider } from './context/CmsContext';

const App: React.FC = () => {
  return (
    <ConfigProvider theme={antTheme}>
      <ThemeProvider theme={theme}>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Routes />
        <GlobalStyle />
      </ThemeProvider>
    </ConfigProvider>
  );
};

export { App };
