export default {
  colors: {
    attention_light: 'rgba(232, 63, 91, 0.5)',
    attention: '#e83f5b',
    background: '#f0f2f5',
    newBackground: '#f5f5f5',
    backgroundNavbar: '#7bba24',
    border: '#CCCCCC',
    tableDark: '#627b80',
    tableLight: '#80a3a8',
    tableHeader: '#e9ffe1',
    danger: '#e24444',
    link: '#70AD47',
    primary: '#5636d3',
    secondary_light: 'rgba(255, 135, 44, 0.3)',
    secondary: '#ff872c',
    shape: '#fff',
    success_light: 'rgba(18,164,84,0.5)',
    success: '#12a454',
    text_dark: '#000000',
    text_blue: '#2e2bc8',
    text_light: '#fff',
    text: '#434343',
    title: '#434343',
    prim: '#80c343',
    dashedButtonColor: '#E1F7DC',
    prim_light: '#93de4e',
    sec: '#89898b',
    sec_light: '#e0e0e0',
    orange: '#FF9900',
    white: '#fff',
    black: '#000',
    red: '#FF0000',
    blue: '#3D85C6',
    blue_secondary: '#1890ff',
    ppb: 'rgb(255, 153, 0, 0.7)',
    fob: 'rgb(166, 77, 121, 0.7)',
    gray_light: '#999999',
    dark_row: '#f5f5f5',
    dark_green: '#5EA320',
    text_disabled: 'rgba(0, 0, 0, 0.25)',
    input_labels: '#6C6C6C',
    labels: '#A9A9A9',
    inputPlaceHolder: 'rgb(0, 0, 0, 0.45)',
    new_background_color: '#F4F6F8',
    horizontal_row_color: '#DEDFE0',
    gray_background: '#FAFAFA',
    gray_border: '#D9D9D9',
    gray_header: '#F4F4F4',
    table_pf_specification: '#E1E3E3',
    table_pf_partnumber: '#C4C7C7',
    border_car_color: '#E3E3E3',
  },
  dimensions: {
    font_title: '1.25rem',
    font_regular: '0.875rem',
    font_title_large: '1.75rem',
    height_small_button: '1.625rem',
    border_radius: '0.25rem',
    border_radius_medium: '0.375rem',
    gap: '0.25rem',
    font_small: '0.75rem',
    border_radius_large: '0.75rem',
  },
  colorsDesignSystem: {
    text: '#434343',
    iconGrey: '#898989',
    secondayText: '#00000085',
    placeholderText: '#00000040',
    primary: '#80C343',
    primaryHoverColor: '#B4DC90',
    blue: '#0c6dff',
    placeHolder: '#999999',
    tableHover: '#EAF0F9',
    pipe: '#CBCBCB',
    tag_background: '#FCFCFC',
    border: '#EDEDED',
    secondaryBorder: '#E3E3E3',
    tertiaryBorder: '#DEDEDE',
    backgroundIcon: '#ECEDF0',
    red: '#ff4d4f',
    red_secondary: '#FF4D4D',
    grayBorder: '#D9D9D9',
    grayDivider: '#EDEDED',
    grayAcer: '#666666',
    fontColor: '#000000cc',
    tabBarActiveColor: '#666666CC',
    tabBarInactiveColor: '#5EA320',
    headerTitleTable: '#f8f8f8',
    headerSubTitleTable: '#d1d2d2',
    headerSubTitleTableText: '#00000099',
    tableHeadSecondary: '#E1E3E3',
    borderFieldTable: '#0000000F',
    gray_background: '#FAFAFA',
    secondaryGrayBackground: '#FBFBFB',
    grayText: '#00000073',
    secondaryGrayText: '#BFBFBF',
    grayBorderTooltip: '#B8B8B8',
    neutralGray: '#ECF0E8',
    white: '#FFFFFF',
    black: '#000000',
    black85: '#000000D9',
    ligthBlack: '#00000025',
    lightBlack: '#00000025',
    multipleSelectOptionActive: '#EDFCD9',
    chartColors: {
      gray: '#1C626D',
      green: '#6cc08b',
      blue: '#0c6dff',
      lightGreen: '#9CE39B',
      orange: '#FF6600',
    },
    newTablesColors: {
      externalTitle: '#262626',
      header: '#F6F8FA',
      projectHeader: '#545D60',
      skuHeader: '#3F484A',
      foreCastHeader: '#869497',
      tableHeaderBorder: '#7D888B',
      fontColorHeader: '#FFF',
      colorSubItem: '#DBE4E6',
      colorFontSubItem: '#434343',
      borderColor: '#d9d9d9',
      colorBackgroundSubItens: '#f6f8fa',
      background: '#F5F5F5',
      borderBottonHeaders: '#7a7a7a',
    },
    textDisabled: '#8c8b8b',
    textEditable: '#0C6DFF',
    cardContentBackground: '#f1f2f4',
    boxShadowCard: '#00000040',
    tagColorBlue: '#6ea5f8',
    tagColorBlueBackground: '#6ea5f81a',
    tagColorGreen: '#71eaba',
    tagBackgroundColorGreen: '#5ad8a61a',
    tagColorGreenBackground: '#80c3001a',
    tagColorOrange: '#ff8b27',
    tagColorOrangeBackground: '#ff8b271a',
    tagColorYellow: '#ffc600',
    tagColorYellowBackground: '#ffc6001f',
    tagColorText: '#5E5E5E',
    tagSupllier: '#8E8E8E',
    backgroundSecondaryColor: '#DCE0E5',
    inputLabelColor: '#6c6c6c',
    pipeSeparatorColor: '#8c8c8c',
    graphicThemeColors: {
      orangeCurrentMonth: '#FF6600',
      orangeOthersMonth: '#FDB07C',
      blueLineChart: '#0C6DFF',
      blueCurrentMonths: '#6B7B98',
      blueOtherMonths: '#AFC8F3',
      aquaCurrentMonths: '#35CBCB',
      aquaOtherMonths: '#9BDEDE',
    },
    shareProportionGraph: {
      orange: '#F79E4F',
      blue: '#8BB7F9',
      yellow: '#FFC600',
      gray: ' #999999',
    },
    rfqIndicationTagColors: {
      greenFont: '#13B675',
      greenBackground: '#5AD8A61A',
      redBackground: '#FFEDEB',
      redFont: '#FF5352',
      blueBackground: '#ECF1FF',
      blueFont: '#6EA5F8',
      orangeBackground: '#FFEDE5',
      orangeFont: '#FFB689',
      purpleBackground: '#F8DDFF',
      purpleFont: '#EFAEFF',
      normalFont: '#5E5E5E',
      normalBackground: '#FFFFFF',
      concludedBackground: '#E8ECEF',
    },
    backgroundTooltipChart: '#ffffffd9',
    boxshadowTooltipChart: '#00000026',
    activeButton: '#4caf50',
    cardBidSelected: '#F6F9F3',
    backgroundDestiny: '#E3ECFF',
    tableValues: '#000000d9',
    table: {
      border: '#D9D9D9',
      headerBg: '#EFEFEF',
      text: '#5F5F5F',
      headerSplit: '#D9D9D9',
      darkRow: '#efefef',
    },
    timeStop: '#B8B8B8',
    link: '#70AD47',
    cardBorder: '#BABFB6',
  },
};
