import { Badge, Button, Form, Popconfirm, Tooltip } from 'antd';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useModal } from '../../../components/Modal/useModal';
import { TableAction } from '../../../components/Tables/TableDropdownAction';
import { getColumnSearchProps } from '../../../helpers/table';
import { getURI, hasPropertyWithHeader, oneAlert } from '../../../helpers/utils';
import { api } from '../../../services/api';
import * as SC from '../../../styles/common';
import { EditButton } from '../styles';
import { useCrateRules } from './Form/useCreateRules';
import * as S from './styles';

const initialParams = {
  order: '',
  orderBy: '',
  page: 0,
  limit: 25,
};

export function useRulesPPB() {
  const { statusCritical, statusHigh, statusGood } = useCrateRules();
  const { t } = useTranslation();
  const { id: prodId } = useParams();

  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const searchInput = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingTitles, setLoadingTitles] = useState(false);
  const [params, setParams] = useState(initialParams);
  const [visible, openModal, closeModal] = useModal();
  const [productInfo, setProductInfo] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  // const isEditing = Object.entries(form.getFieldValue()).length > 0;

  const fetchProductInfo = async () => {
    setLoadingTitles(true);
    try {
      const { data: record } = await api.get(`ppb/${prodId}`);
      setProductInfo(record);
    } catch (error) {
      console.log('Error on the fetch Product');
    }
    setLoadingTitles(false);
  };

  const fetchRulesPPB = async () => {
    setIsLoading(true);
    try {
      const { data: record } = await api.get(getURI(`ppb/${prodId}/rules`, params));
      setData(record);
    } catch (error) {
      console.log('Error on the fetch Forecast');
    }

    setIsLoading(false);
  };
  const onCloseModal = () => {
    form.resetFields();
    form.setFieldsValue({ partTypes: [] });
    closeModal();
  };
  const verifyData = (row) => {
    const verifiFields = { critical: false, highRisk: false, good: false };
    if (
      (row.variablesScore.criticalMax !== null && row.variablesScore.criticalMin !== null) ||
      (row.variablesScore.criticalMax === null && row.variablesScore.criticalMin === null)
    ) {
      verifiFields.critical = true;
    }
    if (
      (row.variablesScore.highMax !== null && row.variablesScore.highMin !== null) ||
      (row.variablesScore.highMax === null && row.variablesScore.highMin === null)
    ) {
      verifiFields.highRisk = true;
    }
    if (
      (row.variablesScore.goodMax !== null && row.variablesScore.goodMin !== null) ||
      (row.variablesScore.goodMax === null && row.variablesScore.goodMin === null)
    ) {
      verifiFields.good = true;
    }

    if (verifiFields.critical && verifiFields.highRisk && verifiFields.good) {
      return true;
    }
    return false;
  };

  const save = async () => {
    setIsLoading(true);
    const row = await form.getFieldsValue(true);
    if (!verifyData(row) && row.bonusStage === 'No') {
      oneAlert('error', t('pages.ppb.fields.variablesScore.saveError'));
      setIsLoading(false);
      return;
    }

    try {
      if (!row.id) {
        const body = {
          bonusStage: row.bonusStage,
          calcBase: row.calcBase,
          description: row.description,
          maxScore: row.maxScore,
          partTypes: row.partTypes,
          variablesScore: row.variablesScore,
        };
        const response = await api.post(
          `ppb/${prodId}/rule`,
          row.bonusStage === 'Yes'
            ? {
                ...body,
                variablesScore: null,
              }
            : body
        );
        if (response.status !== 201) throw Error();
      } else {
        const response = await api.put(
          `ppb/${prodId}/rule/${row.id}`,
          row.bonusStage === 'Yes' ? { ...row, variablesScore: null } : row
        );
        if (response.status !== 201) throw Error();
      }

      await fetchRulesPPB();
      oneAlert('success', t('toast.successOnSave'));
      onCloseModal();
    } catch (error) {
      oneAlert('error', t('toast.errorOnSave'));
    } finally {
      setIsLoading(false);
    }
  };

  const deleteItem = async (itemId) => {
    setIsLoading(true);
    try {
      const response = await api.delete(`ppb/${prodId}/rule/${itemId}`);
      if (response.status !== 200) throw Error();

      await fetchRulesPPB();
      oneAlert('info', t('toast.successOnDelete'));
    } catch (error) {
      oneAlert('error', t('toast.errorOnDelete'));
    }
    setIsLoading(false);
  };

  const openModalEdit = (record) => {
    setIsEditing(true);
    const dataModal = {
      ...record,
      variablesScore: {
        criticalMax: null,
        criticalMin: null,
        goodMax: null,
        goodMin: null,
        highMax: null,
        highMin: null,
      },
    };
    record.variablesScore ? form.setFieldsValue(record) : form.setFieldsValue(dataModal);
    openModal();
  };

  const openModalCreate = (record) => {
    setIsEditing(false);
    const dataModal = {
      ...record,
      variablesScore: {
        criticalMax: null,
        criticalMin: null,
        goodMax: null,
        goodMin: null,
        highMax: null,
        highMin: null,
      },
    };
    form.setFieldsValue(dataModal);
    openModal();
  };

  // const onCloseModal = () => {
  //   form.resetFields();
  //   form.setFieldsValue({ partTypes: [] });
  //   closeModal();
  // };

  const handleHeaderReset = (dataIndex) => {
    const filterParams = { ...params };
    delete filterParams[`header_${dataIndex}`];

    if (!hasPropertyWithHeader(filterParams)) {
      delete filterParams.tipoBusca;
    }

    setParams(filterParams);
  };

  const handleCloseFilteredTag = (field, idx) => {
    if (field?.includes('header_')) {
      const dataIndex = field.replace('header_', '');
      handleHeaderReset(dataIndex);
    } else {
      let newValue;

      if (Array.isArray(params[field])) {
        newValue = [...params[field]];
        newValue.splice(idx, 1);
      } else {
        newValue = '';
      }

      setParams({
        ...params,
        [field]: newValue,
        page: 0,
      });
    }
  };

  const handleHeaderSearch = (dataIndex, formValue) => {
    const value = formValue?.trim();
    if (value?.length) {
      const filterParams = {
        ...params,
        [`header_${dataIndex}`]: value,
        tipoBusca: 'dinamica',
      };

      setParams(filterParams);
    } else {
      handleHeaderReset(dataIndex);
    }
  };

  const columns = [
    {
      title: t('pages.ppb.fields.description'),
      dataIndex: 'description',
      key: 'description',
      width: '15rem',
      ...getColumnSearchProps({
        dataIndex: 'description',
        search: handleHeaderSearch,
        reset: handleHeaderReset,
        filterData: params,
        searchInput,
      }),
    },
    {
      title: t('pages.ppb.fields.calcBase'),
      dataIndex: 'calcBase',
      key: 'calcBase',
      width: '10rem',
      ...getColumnSearchProps({
        dataIndex: 'calcBase',
        search: handleHeaderSearch,
        reset: handleHeaderReset,
        filterData: params,
        searchInput,
      }),
    },
    {
      title: t('pages.ppb.fields.partTypes'),
      dataIndex: 'partTypes',
      key: 'partTypes',
      width: '18rem',
      render: (partTypes) => (
        <>
          {partTypes.map((part) => {
            return (
              <SC.TagCountSubComponent
                subcomponent={Boolean(part.countSubComponents)}
                key={part.name}
              >
                {part.name.toUpperCase()}
              </SC.TagCountSubComponent>
            );
          })}
        </>
      ),
      ...getColumnSearchProps({
        dataIndex: 'partTypes',
        search: handleHeaderSearch,
        reset: handleHeaderReset,
        filterData: params,
        searchInput,
      }),
    },

    {
      title: t('pages.ppb.fields.maxScore'),
      dataIndex: 'maxScore',
      key: 'maxScore',
      align: 'center',
      width: '8rem',
    },
    {
      title: 'Variables Score',
      dataIndex: 'variablesScore',
      key: 'variablesScore',
      align: 'center',
      width: '10rem',
      render: (value, record) => {
        return !record.variablesScore ? (
          <S.BadgeContainer>
            <Badge color="red" text="No Register" />
          </S.BadgeContainer>
        ) : (
          <S.BadgeContainer>
            <Tooltip
              placement="bottom"
              title={
                <S.TooltipContainer>
                  {record.variablesScore.criticalMax && (
                    <S.CriticalContainer>
                      <span>{record.variablesScore?.criticalMin.toFixed(2)}</span>
                      <span className="divider">|</span>
                      <span>{record.variablesScore?.criticalMax.toFixed(2)}</span>
                    </S.CriticalContainer>
                  )}
                  {record.variablesScore.highMax && (
                    <S.HighContainer>
                      <span>{record.variablesScore?.highMin.toFixed(2)}</span>
                      <span className="divider">|</span>
                      <span>{record.variablesScore?.highMax.toFixed(2)}</span>
                    </S.HighContainer>
                  )}
                  {record.variablesScore.goodMax && (
                    <S.GoodContainer>
                      <span>{record.variablesScore?.goodMin.toFixed(2)}</span>
                      <span className="divider">|</span>
                      <span>{record.variablesScore?.goodMax.toFixed(2)}</span>
                    </S.GoodContainer>
                  )}
                </S.TooltipContainer>
              }
            >
              <Badge color="green" text="Register" />
            </Tooltip>
          </S.BadgeContainer>
        );
      },
    },
    {
      title: t('pages.ppb.fields.bonusStage'),
      dataIndex: 'bonusStage',
      key: 'bonusStage',
      align: 'center',
      width: '11rem',
      ...getColumnSearchProps({
        dataIndex: 'bonusStage',
        search: handleHeaderSearch,
        reset: handleHeaderReset,
        filterData: params,
        searchInput,
      }),
    },
    {
      title: t('common.action'),
      key: 'action',
      align: 'center',
      width: '6.25rem',
      render: (_, record) => {
        const actions = [
          {
            key: '1',
            label: (
              <EditButton type="link" onClick={() => openModalEdit(record)} data-cy="edit">
                {t('common.edit')}
              </EditButton>
            ),
          },
          {
            key: '2',
            label: (
              <Popconfirm title={t('common.deleteMessage')} onConfirm={() => deleteItem(record.id)}>
                <Button type="link" danger data-cy="delete">
                  {t('common.delete')}
                </Button>
              </Popconfirm>
            ),
          },
        ];

        return <TableAction dropdownItems={actions} />;
      },
    },
  ];

  return {
    columns,
    handleCloseFilteredTag,
    data,
    params,
    productInfo,
    fetchRulesPPB,
    fetchProductInfo,
    loadingTitles,
    isLoading,
    form,
    save,
    isEditing,
    deleteItem,
    visible,
    openModal,
    openModalEdit,
    onCloseModal,
    openModalCreate,
  };
}
