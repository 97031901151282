import {
  AuditOutlined,
  CloseCircleOutlined,
  FlagOutlined,
  FolderViewOutlined,
  LockOutlined,
  SendOutlined,
  TrophyOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import { Divider, Flex, Steps } from 'antd';
import styled from 'styled-components';

import StyledButton from '../../components/Common/StyledButton';
import { StyledStep } from '../../components/Common/StyledSteps/styles';

interface IBidSupplierViewProps {
  iconsStatus?: 'finish' | 'wait';
}

export const SpanTile = styled.span`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
  padding-right: 0;
`;

export const BidComponents = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colorsDesignSystem.grayAcer};
  margin: 2rem 0.25rem;
`;

export const SupplierSpan = styled.span`
  font-size: 1.25rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
`;

export const UserContainer = styled.div`
  display: flex;
  padding-left: 0.25rem;
  flex-direction: row;
  gap: 0.25rem;
  justify-content: center;
  align-items: center;
  border-left: 0.125rem solid ${({ theme }) => theme.colorsDesignSystem.pipeSeparatorColor};

  span {
    color: ${({ theme }) => theme.colorsDesignSystem.grayAcer};
    font-size: 1.25rem;
  }
`;

export const StepsContainer = styled(StyledStep)`
  display: flex;
  justify-content: center;
  width: 100%;
  border: 0.063rem solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  border-radius: 0.75rem;
  height: 7.438rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.white};
  margin: 0.75rem 0 1.5rem;
  padding: 1.5rem 2rem 0 2rem;

  .ant-steps .ant-steps-item-title::after {
    background-color: ${({ theme }) => theme.colorsDesignSystem.blue};
  }

  .ant-steps
    .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: ${({ theme }) => theme.colorsDesignSystem.blue};
  }

  .ant-steps
    .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: ${({ theme }) => theme.colorsDesignSystem.blue};
  }

  .ant-steps .ant-steps-item-process.ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
    color: ${({ theme }) => theme.colorsDesignSystemblue};
  }

  .ant-steps .ant-steps-item-finish.ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
    color: ${({ theme }) => theme.colorsDesignSystem.blue};
  }

  .ant-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: ${({ theme }) => theme.colors.sec};
  }
`;

export const StepsComponent = styled(Steps)`
  width: 80%;
  height: 100%;
`;

export const UnlockIcon = styled(UnlockOutlined)<IBidSupplierViewProps>`
  color: ${({ theme, iconsStatus }) =>
    iconsStatus === 'finish'
      ? `${theme.colorsDesignSystem.blue}`
      : `${theme.colorsDesignSystem.iconGrey}`};
`;

export const FlagIcon = styled(FlagOutlined)<IBidSupplierViewProps>`
  color: ${({ theme, iconsStatus }) =>
    iconsStatus === 'finish'
      ? `${theme.colorsDesignSystem.blue}`
      : `${theme.colorsDesignSystem.iconGrey}`};
`;

export const SendIcon = styled(SendOutlined)<IBidSupplierViewProps>`
  color: ${({ theme, iconsStatus }) =>
    iconsStatus === 'finish'
      ? `${theme.colorsDesignSystem.blue}`
      : `${theme.colorsDesignSystem.iconGrey}`};
`;

export const LockIcon = styled(LockOutlined)<IBidSupplierViewProps>`
  color: ${({ theme, iconsStatus }) =>
    iconsStatus === 'finish'
      ? `${theme.colorsDesignSystem.blue}`
      : `${theme.colorsDesignSystem.iconGrey}`};
`;

export const FolderIcon = styled(FolderViewOutlined)<IBidSupplierViewProps>`
  color: ${({ theme, iconsStatus }) =>
    iconsStatus === 'finish'
      ? `${theme.colorsDesignSystem.blue}`
      : `${theme.colorsDesignSystem.iconGrey}`};
`;

export const TrophyIcon = styled(TrophyOutlined)<IBidSupplierViewProps>`
  color: ${({ theme, iconsStatus }) =>
    iconsStatus === 'finish'
      ? `${theme.colorsDesignSystem.blue}`
      : `${theme.colorsDesignSystem.iconGrey}`};
`;

export const DescriptionStepConteiner = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colorsDesignSystem.grayAcer};
`;

export const DataDescription = styled.span`
  font-size: 0.625rem;
`;

export const UserDescriptionContainer = styled.span`
  font-size: 0.625rem;
  font-style: italic;
  font-weight: 600;
  span {
    font-weight: 400;
  }
`;

export const ContentContainer = styled.div`
  margin-top: 1.938rem;
  display: flex;
  flex-direction: row;
  gap: 1.625rem;
`;

export const ContentLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.white};
  width: 55%;
  border: 0.063rem solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
`;

export const HeaderContentLeft = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.75rem 0.75rem 0 0;
  border-bottom: 0.063rem solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  padding: 1.938rem;
  height: 4rem;
`;

export const TitleCard = styled.span`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
`;

export const CardsBidContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.875rem;
  height: 31.25rem;
  gap: 1rem;
  overflow-y: auto;
`;

export const ContentRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.white};
  width: 45%;
  border: 0.063rem solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
`;

export const Padding = styled(Flex)`
  flex-direction: column;
  padding: 2rem 1.5rem;
  gap: 1.5rem;
`;

export const HeaderContentRight = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.75rem 0.75rem 0 0;
  border-bottom: 0.063rem solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  padding: 1.938rem;
  height: 4rem;
`;

export const RoundContenteContainer = styled.div`
  border: 0.063rem solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  border-radius: ${({ theme }) => theme.dimensions.border_radius_large};
  min-height: 6.875rem;
  background-color: ${({ theme }) =>
    theme.colorsDesignSystem.newTablesColors.colorBackgroundSubItens};

  padding: 1.5rem 1.781rem;
`;
export const PotentialLabel = styled.span`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
`;
export const PotentialValue = styled.span`
  font-size: 2rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
`;

export const TermsConditionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.063rem solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  border-radius: ${({ theme }) => theme.dimensions.border_radius_large};
  min-height: 6.875rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.newTablesColors.white};
  padding: 1.5rem 1.781rem;
`;

export const AuditIcon = styled(AuditOutlined)`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colorsDesignSystem.iconGrey};
`;

export const TermsConditionsButton = styled(StyledButton)`
  font-size: 1rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colorsDesignSystem.tabBarInactiveColor};
`;

export const TermsConditionsTitleModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ModalDivider = styled(Divider)`
  margin-top: 0;
`;

export const StatusIcons = styled(CloseCircleOutlined)`
  color: ${({ theme }) => theme.colorsDesignSystem.red};
`;

export const StatusTitle = styled.span`
  font-size: 1rem;
  font-weight: 600;
`;
