import { Flex, Tooltip } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import * as S from './styles';
import { useFinance } from './useFinance';

import { handleFormatValueFields } from '../../helpers/nUtils';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { AverageCard } from './Components/AverageCard';
import { CBOMCard } from './Components/CBOMCard';
import { FinancialMetricsCard } from './Components/FinancialMetricsCard';
import { ShareProportionCard } from './Components/ShareProportionCard';
import { FinancialMetricsProps, PricesPageProps, ShareProportionProps } from './types';

export function FinancePage(props: any) {
  const { t } = useTranslation();
  const { search } = useLocation();

  useDocumentTitle(t('pages.finance.title'));

  const {
    openCbomCard,
    cbomItens,
    columns,
    averageColumns,
    priceProps,
    isLoadingCbom,
    openShareProportionCard,
    shareProportionProps,
    shareProportionData,
    isLoadingShareProportion,
    cardPartNumber,
    financialMetricsData,
    fiancialMetricsProps,
    fetchFinancialMetrics,
    setFiancialMetricsProps,
    setShareProportionProps,
    setShareProportionCard,
    setPriceProps,
    setOpenCbomCard,
    handleFetchCbomItens,
    handleFormatQuotePrice,
    handleFetchShareProportion,
    openAvarege,
    setOpenAvarege,
    averageData,
    isLoadingAverage,
    setCardPartNumber,
  } = useFinance();

  useEffect(() => {
    const urlParams = new URLSearchParams(search);

    const params = {
      level: urlParams.get('level'),
      compType: urlParams.get('compType'),
      sku: urlParams.get('sku'),
      monthYear: urlParams.get('monthYear'),
      category: urlParams.get('category'),
      cm: urlParams.get('cm'),
      source: urlParams.get('source'),
      project: urlParams.get('project'),
      quotePrice: urlParams.get('quotePrice'),
    };
    setPriceProps(params as PricesPageProps);
    setShareProportionProps(params as ShareProportionProps);
    setFiancialMetricsProps(params as FinancialMetricsProps);
  }, [search]);

  useEffect(() => {
    priceProps && handleFetchCbomItens();
  }, [priceProps]);

  useEffect(() => {
    shareProportionProps && handleFetchShareProportion();
  }, [shareProportionProps]);

  return (
    <S.Container>
      <S.TitlePage>{`${priceProps?.sku || '-'} | ${priceProps?.project || '-'}`}</S.TitlePage>
      <S.InfoContainer>
        <S.QuotePriceContainer>
          <S.LabelInfoContainer>{t('pages.financePage.labels.quuotesPrice')}</S.LabelInfoContainer>
          <S.QuotesInfoPriceContainer>
            <span>USD</span>
            <Tooltip
              placement="right"
              title={handleFormatValueFields({
                number: priceProps?.quotePrice || '',
                minFractional: 4,
                maxFactional: 4,
              })}
            >
              <p>{handleFormatQuotePrice(priceProps?.quotePrice)}</p>
            </Tooltip>
          </S.QuotesInfoPriceContainer>
        </S.QuotePriceContainer>
        <S.OtherInformationsPriceContainer>
          <S.SingleInformationPriceContainer>
            <S.LabelInfoContainer>{t('pages.financePage.labels.monthYear')}</S.LabelInfoContainer>
            <S.InfoContainerText>{priceProps?.monthYear || '-'}</S.InfoContainerText>
          </S.SingleInformationPriceContainer>
          <S.SingleInformationPriceContainer>
            <S.LabelInfoContainer>{t('pages.financePage.labels.category')}</S.LabelInfoContainer>
            <S.InfoContainerText>{priceProps?.category || '-'}</S.InfoContainerText>
          </S.SingleInformationPriceContainer>
          <S.SingleInformationPriceContainer>
            <S.LabelInfoContainer>{t('pages.financePage.labels.cm')}</S.LabelInfoContainer>
            <S.InfoContainerText>{priceProps?.cm || '-'}</S.InfoContainerText>
          </S.SingleInformationPriceContainer>
          <S.SingleInformationPriceContainer>
            <S.LabelInfoContainer>{t('pages.financePage.labels.source')}</S.LabelInfoContainer>
            <S.InfoContainerText>{priceProps?.source || '-'}</S.InfoContainerText>
          </S.SingleInformationPriceContainer>
        </S.OtherInformationsPriceContainer>
      </S.InfoContainer>
      <Flex vertical gap={24}>
        <CBOMCard
          cbomItens={cbomItens}
          columns={columns}
          priceProps={priceProps}
          isLoading={isLoadingCbom}
          openCbomCard={openCbomCard}
          setOpenCbomCard={() => {
            setOpenCbomCard(!openCbomCard);
          }}
        />
        <ShareProportionCard
          category={priceProps?.category}
          data={shareProportionData}
          isLoading={isLoadingShareProportion}
          clickCard={setCardPartNumber}
          openShareProportioncard={openShareProportionCard}
          toogleShareProportionCard={() => setShareProportionCard(!openShareProportionCard)}
        />
        <AverageCard
          partNumberProps={averageData}
          columns={averageColumns}
          averageItems={averageData?.data || []}
          isLoading={isLoadingAverage}
          openAverageCard={openAvarege}
          setOpenAverageCard={() => setOpenAvarege(!openAvarege)}
        />
        <FinancialMetricsCard componentName={cardPartNumber} data={financialMetricsData} />
      </Flex>
    </S.Container>
  );
}
