import { Flex, Tooltip } from 'antd';
import * as S from './styles';
import { IVerticalCard } from './types';

export function VerticalCard({
  title,
  value,
  valueBlue = false,
  font = 'MD',
  fontTitle = 'MD',
  gap = 12,
  revertRow = false,
  addTooltip,
}: IVerticalCard) {
  if (revertRow) {
    return (
      <Flex vertical gap={gap}>
        {addTooltip ? (
          <Tooltip title={addTooltip}>
            <S.Value isBlue={valueBlue} font={font}>
              {value}
            </S.Value>
          </Tooltip>
        ) : (
          <S.Value isBlue={valueBlue} font={font}>
            {value}
          </S.Value>
        )}
        <S.Title font={fontTitle}>{title}</S.Title>
      </Flex>
    );
  }

  return (
    <Flex vertical gap={gap}>
      <S.Title font={fontTitle}>{title}</S.Title>

      {addTooltip ? (
        <Tooltip title={addTooltip}>
          <S.Value isBlue={valueBlue} font={font}>
            {value}
          </S.Value>
        </Tooltip>
      ) : (
        <S.Value isBlue={valueBlue} font={font}>
          {value}
        </S.Value>
      )}
    </Flex>
  );
}
