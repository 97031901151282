import styled from 'styled-components';

interface ProjectHeaderProps {
  qtSkus: number;
}

interface TypeContentTableData {
  isComponentLink: boolean;
}

export const container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
  overflow-y: hidden;
`;

export const TableProjectHeader = styled.th<ProjectHeaderProps>`
  font-size: 1rem;
  font-weight: bold;
  word-break: break-all;
  flex-direction: row;
  display: flex;
  min-width: calc(${(props) => props.qtSkus} * 7.75rem);
  justify-content: center;
  text-align: center;
  border-left: 1px solid
    ${({ theme }) => theme.colorsDesignSystem.newTablesColors.tableHeaderBorder};
  :last-child {
    border-right: 1px solid
      ${({ theme }) => theme.colorsDesignSystem.newTablesColors.tableHeaderBorder};
  }
`;

export const TableContainer = styled.table`
  width: 100%;
`;

export const TableHeaderProjectModelName = styled.th`
  height: 3.438rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 2.375rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.newTablesColors.projectHeader};
  gap: 1px;
  color: ${({ theme }) => theme.colorsDesignSystem.newTablesColors.fontColorHeader};
  border-bottom: 1px solid
    ${({ theme }) => theme.colorsDesignSystem.newTablesColors.borderBottonHeaders};
  div {
    min-width: 23.063rem;
    display: flex;
    font-weight: normal;
    color: ${({ theme }) => theme.colorsDesignSystem.newTablesColors.fontColorHeader};
  }
`;

export const TableHeaderSkus = styled.th`
  height: 2.375rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.875rem;
  padding-left: 2.375rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.newTablesColors.skuHeader};
  border-bottom: 1px solid
    ${({ theme }) => theme.colorsDesignSystem.newTablesColors.borderBottonHeaders};
  div {
    min-width: 23.063rem;
    color: white;
    display: flex;
    font-weight: normal;
  }

  td {
    display: flex;
    width: 7.75rem;
    justify-content: flex-end;
    color: white;
    font-size: 0.875rem;
    font-weight: normal;
    padding-right: 1rem;
    border-left: 1px solid
      ${({ theme }) => theme.colorsDesignSystem.newTablesColors.tableHeaderBorder};
    :last-child {
      border-right: 1px solid
        ${({ theme }) => theme.colorsDesignSystem.newTablesColors.tableHeaderBorder};
    }
  }
`;

export const TableHeaderForecast = styled.tr`
  height: 2.375rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 2.375rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.newTablesColors.foreCastHeader};

  div {
    min-width: 23.063rem;
    display: flex;
    justify-content: flex-start;
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: white;
  }

  th {
    display: flex;
    width: 7.75rem;
    justify-content: flex-end;
    color: white;
    font-size: 0.875rem;
    font-weight: bold;
    padding-right: 1rem;
    border-left: 1px solid white;
    :last-child {
      border-right: 1px solid white;
    }
  }
`;

export const HeaderCategories = styled.tr`
  height: 2.375rem;
  padding-left: 2.375rem;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colorsDesignSystem.newTablesColors.colorSubItem};

  th {
    display: flex;
    width: 7.74rem;
    justify-content: flex-end;
    align-items: center;
    color: #${({ theme }) => theme.colorsDesignSystem.newTablesColors.colorFontSubItem};
  }

  th > p {
    font-size: 0.875rem;
    font-weight: normal;
    align-self: center;
    color: ${({ theme }) => theme.colorsDesignSystem.newTablesColors.colorFontSubItem};
    margin: 0;
  }
`;

export const TableBodyExpandable = styled.tbody`
  tr {
    height: 2.375rem;
    padding-left: 2.375rem;
    display: flex;
    align-self: start;
    align-items: center;
    background-color: #f6f8fa;
  }

  //selecionando filhos ímpares da tabela para efeito zebrado
  tr:nth-child(even) {
    background-color: white;
  }

  /* td {
    display: flex;
    min-width: 7.75rem;
    justify-content: flex-end;
    font-weight: normal;
    color: red;
    font-size: 0.875rem;
  } */
  span.table-body {
    min-width: 5.313rem;
  }

  div.table-body {
    min-width: 17.75rem;
  }
`;

export const TableData = styled.td<{ isComponentLink: boolean }>`
  display: flex;
  min-width: 7.75rem;
  font-weight: normal;
  font-size: 0.875rem;
  justify-content: flex-end;
  color: ${({ theme, isComponentLink }) =>
    isComponentLink ? theme.colorsDesignSystem.tabBarInactiveColor : theme.colorsDesignSystem.text};

  cursor: ${(props: TypeContentTableData) => (props.isComponentLink ? 'pointer' : 'default')};
`;

export const EmptyTbody = styled.tbody`
  min-height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.5px solid #d9d9d9;
`;

export const EstimatedForecastContainer = styled.div`
  display: flex;
  padding-right: 3.5rem;
`;
