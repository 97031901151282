/* eslint-disable no-undefined */
import { InfoCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { HttpStatusCode } from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styles';
import {
  CbomData,
  CbomItem,
  FinancialMetricsData,
  FinancialMetricsProps,
  IAverageData,
  IAverageItem,
  PricesPageProps,
  ShareProportionData,
  ShareProportionProps,
} from './types';

import { handleFormatValueFields } from '../../helpers/nUtils';
import { getURI, oneAlert } from '../../helpers/utils';
import { api } from '../../services/api';
import theme from '../../styles/theme';

export function useFinance() {
  const [openCbomCard, setOpenCbomCard] = useState<boolean>(true);
  const [openShareProportionCard, setShareProportionCard] = useState<boolean>(true);
  const [openAvarege, setOpenAvarege] = useState<boolean>(true);
  const { t } = useTranslation();
  const [priceProps, setPriceProps] = useState<PricesPageProps>();
  const [shareProportionProps, setShareProportionProps] = useState<ShareProportionProps>();
  const [shareProportionData, setShareProportionData] = useState<Array<ShareProportionData>>([]);
  const [isLoadingCbom, setIsLoadingCbom] = useState(false);
  const [isLoadingShareProportion, setIsLoadingShareProportion] = useState(false);
  const [cardPartNumber, setCardPartNumber] = useState<string>('');
  const [isLoadingAverage, setIsLoadingAverage] = useState(false);
  const [averageData, setAverageData] = useState<IAverageData>();
  const [cbomItens, setCbomItens] = useState<Array<CbomItem>>([]);
  const [financialMetricsData, setFinancialMetricsData] = useState<FinancialMetricsData>();
  const [fiancialMetricsLoading, setFiancialMetricsLoading] = useState(false);
  const [fiancialMetricsProps, setFiancialMetricsProps] = useState<FinancialMetricsProps>();

  const handleFetchCbomItens = async () => {
    try {
      setIsLoadingCbom(true);
      const { data, status }: { data: CbomData; status: HttpStatusCode } = await api.get(
        getURI('quotations/details/', {
          level: priceProps?.level,
          sku: priceProps?.sku,
          date: priceProps?.monthYear,
          compType: priceProps?.compType,
        })
      );
      if (status === 200) {
        return setCbomItens(data.content);
      }
      setCbomItens([]);
    } catch (error: any) {
      console.log(error);
      setCbomItens([]);
      oneAlert('error', error.message || t('toast.errorOnList'));
    } finally {
      setIsLoadingCbom(false);
    }
  };

  const handleFetchShareProportion = async () => {
    try {
      setIsLoadingShareProportion(true);
      const { data, status }: { data: Array<ShareProportionData>; status: HttpStatusCode } =
        await api.get(
          getURI('shareProportion/quotation', {
            cm: shareProportionProps?.cm,
            date: shareProportionProps?.monthYear,
            sku: shareProportionProps?.sku,
            projectName: shareProportionProps?.project,
            level: shareProportionProps?.level,
            sourcing: shareProportionProps?.category,
          })
        );
      if (status === 200) {
        setCardPartNumber(data[0]?.partNumber);
        return setShareProportionData(data);
      }
      setShareProportionData([]);
    } catch (error: any) {
      console.log(error);
      setShareProportionData([]);
      oneAlert('error', error.message || t('toast.errorOnList'));
    } finally {
      setIsLoadingShareProportion(false);
    }
  };

  const handleFormatQuotePrice = (value = '-'): string => {
    try {
      return Number(value).toFixed(2).toString() || '-';
    } catch (error) {
      console.log(error);
      return '-';
    }
  };

  const fetchAveragePrice = async () => {
    try {
      setIsLoadingAverage(true);
      const { data, status }: { data: IAverageData; status: HttpStatusCode } = await api.get(
        getURI('quotations/average/details', {
          cm: shareProportionProps?.cm || 'Foxconn',
          partNumber: cardPartNumber,
        })
      );
      if (status === 200) {
        setAverageData(data);
        return;
      }
      if (status === 204) {
        setAverageData(undefined);
      }
    } catch (error: any) {
      oneAlert('error', error.message || t('toast.errorOnList'));
      setAverageData(undefined);
    } finally {
      setIsLoadingAverage(false);
    }
  };

  const fetchFinancialMetrics = async () => {
    try {
      const { data, status }: { data: any; status: HttpStatusCode } = await api.get(
        getURI('/dashboard/finance/metrics', {
          cm: fiancialMetricsProps?.cm,
          date: fiancialMetricsProps?.monthYear,
          level: fiancialMetricsProps?.level,
          partNumber: cardPartNumber,
        })
      );
      if (status === 200) {
        setFinancialMetricsData(data);
        return;
      }
    } catch (error: any) {
      oneAlert('error', error.message || t('toast.errorOnList'));
      setFinancialMetricsData(undefined);
    }
  };

  useEffect(() => {
    if (cardPartNumber) {
      fetchAveragePrice();
      fetchFinancialMetrics();
    }
  }, [cardPartNumber]);

  const handleFormatQtyValue = (value: string): number => {
    try {
      return Number(value);
    } catch (error) {
      return 0;
    }
  };

  const columns: ColumnsType<CbomItem> = [
    // part number
    {
      title: () => {
        return (
          <S.HeaderCellTable>
            <span>{t(`pages.financePage.tableHeaders.partNumber`)}</span>
          </S.HeaderCellTable>
        );
      },

      dataIndex: 'partNumber',
      key: 'partNumber',
      align: 'left',

      render: (_, record) => {
        return (
          <S.DataCellTableContent>
            <span>{record.partNumber}</span>
            <Tooltip placement="topLeft" title={record.description}>
              <InfoCircleFilled style={{ color: theme.colors.dark_green }} />
            </Tooltip>
          </S.DataCellTableContent>
        );
      },
    },
    //demand
    {
      title: () => {
        return (
          <S.HeaderCellTable>
            <span>{t(`pages.financePage.tableHeaders.demand`)}</span>
          </S.HeaderCellTable>
        );
      },
      dataIndex: 'demand',
      key: 'demand',
      align: 'right',

      render: (_, record) => {
        return (
          <S.DataCellTableContent className="right">
            <span>
              {handleFormatValueFields({
                number: record.demand,
                minFractional: 0,
                maxFactional: 0,
              })}
            </span>
          </S.DataCellTableContent>
        );
      },
    },
    //QTY

    {
      title: () => {
        return (
          <S.HeaderCellTable>
            <span>{t(`pages.financePage.tableHeaders.qty`)}</span>
          </S.HeaderCellTable>
        );
      },
      dataIndex: 'qty',
      key: 'qty',
      align: 'center',

      // render: (_, record) => {
      //   // return <p>{record.qty}</p>;
      //   return (
      //     <S.DataCellTableContent>
      //       <Progress
      //         percent={Number(record.qty) || 0}
      //         style={{ minWidth: '100px' }}
      //         strokeColor={
      //           handleFormatQtyValue(record.qty) <= 50
      //             ? theme.colorsDesignSystem.red
      //             : theme.colorsDesignSystem.blue
      //         }
      //       />
      //     </S.DataCellTableContent>
      //   );
      // },
    },
    //unit price
    {
      title: () => {
        return (
          <S.HeaderCellTable>
            <span>{t(`pages.financePage.tableHeaders.unitPrice`)}</span>
          </S.HeaderCellTable>
        );
      },
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      align: 'right',
      render: (_, record) => {
        return (
          <S.DataCellTableContent className="right">
            <Tooltip
              placement="right"
              title={handleFormatValueFields({
                number: record.unitPrice,
                minFractional: 4,
                maxFactional: 4,
              })}
            >
              <span>{handleFormatValueFields({ number: record.unitPrice })}</span>
            </Tooltip>
          </S.DataCellTableContent>
        );
      },
    },
    //total invoice
    {
      title: () => {
        return (
          <S.HeaderCellTable>
            <span>{t(`pages.financePage.tableHeaders.totalInvoiceFbr`)}</span>
          </S.HeaderCellTable>
        );
      },
      dataIndex: 'totaoInvoice',
      key: 'totaoInvoice',
      align: 'right',
      render: (_, record) => {
        return (
          <S.DataCellTableContent className="right">
            <Tooltip
              placement="right"
              title={handleFormatValueFields({
                number: record.totalInvoiceFbr,
                minFractional: 4,
                maxFactional: 4,
              })}
            >
              <span>{handleFormatValueFields({ number: record.totalInvoiceFbr })}</span>
            </Tooltip>
          </S.DataCellTableContent>
        );
      },
    },
    //II %
    {
      title: () => {
        return (
          <S.HeaderCellTable>
            <span>{t(`pages.financePage.tableHeaders.ii`)}</span>
          </S.HeaderCellTable>
        );
      },
      dataIndex: 'ii',
      key: 'ii',
      align: 'right',
      render: (_, record) => {
        return (
          <S.DataCellTableContent className="right">
            <span>{record.ii || '-'}</span>
          </S.DataCellTableContent>
        );
      },
    },
    //cofins
    {
      title: () => {
        return (
          <S.HeaderCellTable>
            <span>{t(`pages.financePage.tableHeaders.cofins`)}</span>
          </S.HeaderCellTable>
        );
      },
      dataIndex: 'cofins',
      key: 'cofins',
      align: 'right',
      render: (_, record) => {
        return (
          <S.DataCellTableContent className="right">
            <Tooltip
              placement="right"
              title={handleFormatValueFields({
                number: record.cofins,
                minFractional: 4,
                maxFactional: 4,
              })}
            >
              <span>{handleFormatValueFields({ number: record.cofins })}</span>
            </Tooltip>
          </S.DataCellTableContent>
        );
      },
    },
    //cif
    {
      title: () => {
        return (
          <S.HeaderCellTable>
            <span>{t(`pages.financePage.tableHeaders.cif`)}</span>
          </S.HeaderCellTable>
        );
      },
      dataIndex: 'cif',
      key: 'cif',
      align: 'right',
      render: (_, record) => {
        return (
          <S.DataCellTableContent className="right">
            <Tooltip
              placement="right"
              title={handleFormatValueFields({
                number: record.cif,
                minFractional: 4,
                maxFactional: 4,
              })}
            >
              <span>{handleFormatValueFields({ number: record.cif })}</span>
            </Tooltip>
          </S.DataCellTableContent>
        );
      },
    },
    //vendor Name
    {
      title: () => {
        return (
          <S.HeaderCellTable>
            <span>{t(`pages.financePage.tableHeaders.vendorName`)}</span>
          </S.HeaderCellTable>
        );
      },
      dataIndex: 'vendorName',
      key: 'vendorName',
      align: 'left',
      render: (_, record) => {
        return (
          <S.DataCellTableContent>
            <span>{record.vendorName}</span>
          </S.DataCellTableContent>
        );
      },
    },
  ];

  const averageColumns: ColumnsType<IAverageItem> = [
    {
      title: <b>{t('pages.financePage.average.partNumber')}</b>,
      dataIndex: 'partNumber',
      key: 'partNumber',
      align: 'left',
      width: '8.375rem',
    },
    {
      title: <b>{t('pages.financePage.average.qtyPurchase')}</b>,
      dataIndex: 'qtyPurchase',
      key: 'qtyPurchase',
      align: 'right',
      width: '6rem',
      render: (_, record) => {
        return (
          <span>
            {handleFormatValueFields({
              number: record.qtyPurchase,
              maxFactional: 0,
              minFractional: 0,
            })}
          </span>
        );
      },
    },
    {
      title: <b>{t('pages.financePage.average.pricePurchase')}</b>,
      dataIndex: 'pricePurchase',
      key: 'pricePurchase',
      align: 'right',
      width: '6.25rem',
      render: (_, record) => {
        return (
          <span>
            ${' '}
            {handleFormatValueFields({
              number: record.pricePurchase,
              maxFactional: 4,
              minFractional: 4,
            })}
          </span>
        );
      },
    },
    {
      title: <b>{t('pages.financePage.average.purchaseInv')}</b>,
      dataIndex: 'purchaseInv',
      key: 'purchaseInv',
      align: 'center',
      width: '6.25rem',
      ellipsis: true,
    },
    {
      title: <b>{t('pages.financePage.average.invDate')}</b>,
      dataIndex: 'invoiceDate',
      key: 'invoiceDate',
      align: 'center',
      width: '6.25rem',
    },
    {
      title: <b>{t('pages.financePage.average.weightAveragePrice')}</b>,
      dataIndex: 'weightAveragePriceUSD',
      key: 'weightAveragePriceUSD',
      align: 'right',
      width: '6.25rem',
      className: 'highlighted-column',
      render: (_, record) => {
        return (
          <span>
            {handleFormatValueFields({
              number: record.weightAveragePriceUSD,
              maxFactional: 4,
              minFractional: 4,
            })}
          </span>
        );
      },
    },
    {
      title: <b>{t('pages.financePage.average.fgQuoteDemand')}</b>,
      dataIndex: 'fgQuoteDemand',
      key: 'fgQuoteDemand',
      align: 'center',
      width: '6.25rem',
    },
    {
      title: <b>{t('pages.financePage.average.demand')}</b>,
      dataIndex: 'demandQty',
      key: 'demandQty',
      align: 'right',
      width: '5.125rem',
      render: (_, record) => {
        if (record.remark) {
          return (
            <Tooltip title={record.remark} placement="right">
              <S.Demand isBlue>
                {handleFormatValueFields({
                  number: record.demandQty,
                  maxFactional: 0,
                  minFractional: 0,
                })}
              </S.Demand>
            </Tooltip>
          );
        }

        return (
          <S.Demand>
            {handleFormatValueFields({
              number: record.demandQty,
              maxFactional: 0,
              minFractional: 0,
            })}
          </S.Demand>
        );
      },
    },
    {
      title: <b>{t('pages.financePage.average.totalPO')}</b>,
      dataIndex: 'totalPOUSD',
      key: 'totalPOUSD',
      align: 'center',
      width: '7rem',
      render: (_, record) => {
        return (
          <span>
            ${' '}
            {handleFormatValueFields({
              number: record.totalPOUSD,
              maxFactional: 3,
              minFractional: 3,
            })}
          </span>
        );
      },
    },
    {
      title: <b>{t('pages.financePage.average.totalQuote')}</b>,
      dataIndex: 'totalQuoteUSD',
      key: 'totalQuoteUSD',
      align: 'center',
      width: '7rem',
      render: (_, record) => {
        return (
          <span>
            ${' '}
            {handleFormatValueFields({
              number: record.totalQuoteUSD,
              maxFactional: 3,
              minFractional: 3,
            })}
          </span>
        );
      },
    },
    {
      title: <b>{t('pages.financePage.average.currentAvgInv')}</b>,
      dataIndex: 'currentAvgInventoryUnit',
      key: 'currentAvgInventoryUnit',
      align: 'center',
      width: '6.25rem',
      render: (_, record) => {
        return (
          <span>
            {handleFormatValueFields({
              number: record.beforeUseAvgInventoryUnit,
              maxFactional: 0,
              minFractional: 0,
            })}
          </span>
        );
      },
    },
    {
      title: <b>{t('pages.financePage.average.beforeUseInventory')}</b>,
      dataIndex: 'beforeUseInventory',
      key: 'beforeUseInventory',
      align: 'center',
      width: '6.25rem',
      render: (_, record) => {
        return (
          <span>
            {handleFormatValueFields({
              number: record.beforeUseInventory,
              maxFactional: 0,
              minFractional: 0,
            })}
          </span>
        );
      },
    },
    {
      title: <b>{t('pages.financePage.average.beforeUseAvg')}</b>,
      dataIndex: 'beforeUseAvgInventoryUnit',
      key: 'beforeUseAvgInventoryUnit',
      align: 'center',
      width: '6.25rem',
      render: (_, record) => {
        return (
          <span>
            ${' '}
            {handleFormatValueFields({
              number: record.beforeUseAvgInventoryUnit,
              maxFactional: 2,
              minFractional: 2,
            })}
          </span>
        );
      },
    },
  ];

  return {
    openCbomCard,
    cbomItens,
    columns,
    averageColumns,
    priceProps,
    isLoadingCbom,
    openShareProportionCard,
    shareProportionProps,
    shareProportionData,
    isLoadingShareProportion,
    cardPartNumber,
    financialMetricsData,
    fiancialMetricsProps,
    fetchFinancialMetrics,
    setFiancialMetricsProps,
    setCardPartNumber,
    setShareProportionProps,
    setShareProportionCard,
    setOpenCbomCard,
    setPriceProps,
    handleFetchCbomItens,
    handleFormatQuotePrice,
    handleFetchShareProportion,
    setOpenAvarege,
    openAvarege,
    isLoadingAverage,
    averageData,
  };
}
